import React, { useState } from 'react';
import ThemeLink from 'components/UI/ThemeLink';
import Mask from './Mask';
import { CardLink } from './styles';

const Card = ({ card, parentPath }) => {
  const { parentResource, title, handle } = card;
  const goTo = `${parentPath}/${parentResource.handle}/${handle}`;

  const [hovered, setHovered] = useState(false);

  return (
    <CardLink
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ThemeLink
        to={goTo}
        bordered={false}
        icon="right-arrow-ellipse"
        theme="secondary"
        topSpacingMobile="0"
        bottomSpacingMobile="0"
        topSpacingTablet="0"
        bottomSpacingTablet="0"
        topSpacingDesktop="0"
        bottomSpacingDesktop="0"
        exitDelay={2}
      >
        {title}
      </ThemeLink>
      {hovered && <Mask />}
    </CardLink>
  );
};

export default Card;
