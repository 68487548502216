import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar } from 'swiper';
import useWindowSizes from 'utils/hooks/useWindowSizes';
import Icon from 'components/Icon';
import Card from './components/Card';
import {
  StyledSliderWrapper,
  StyledSliderContainer,
  StyledSlider,
  StyledNav,
  StyledPrev,
  StyledNext,
} from './styles';

const CardsSlider = ({ slides, parentPath, slideClick }) => {
  const mobile = useWindowSizes().windowWidth < 768;

  const [beginning, setBeginning] = useState(true);
  const [end, setEnd] = useState(false);

  SwiperCore.use([Navigation, Scrollbar]);

  const SlideStyle = {
    width: 'fit-content',
    height: 'auto',
    display: 'flex',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  return (
    <StyledSliderWrapper>
      <StyledSliderContainer>
        <StyledSlider
          slideVisibleClass={true}
          slidesPerView={1.83}
          spaceBetween={15}
          slidesOffsetAfter={20}
          scrollbar={{ hide: false }}
          onSlideChange={(swiper) => {
            swiper.isBeginning ? setBeginning(true) : setBeginning(false);
            swiper.isEnd ? setEnd(true) : setEnd(false);
          }}
          navigation={{
            prevEl: '.Nav-Prev',
            nextEl: '.Nav-Next',
          }}
          freeMode={true}
          breakpoints={{
            600: {
              slidesOffsetAfter: 30,
            },
            768: {
              slidesPerView: 2.18,
              spaceBetween: 30,
              slidesOffsetAfter: 30,
            },
            1440: {
              slidesPerView: 3.616,
              spaceBetween: 30,
              slidesOffsetAfter: 30,
            },
            1920: {
              slidesPerView: 4.285,
              spaceBetween: 30,
              slidesOffsetAfter: 30,
            },
          }}
        >
          {slides &&
            slides.map((sl) => (
              <SwiperSlide key={sl.id} style={SlideStyle} onClick={slideClick}>
                <Card card={sl} parentPath={parentPath} exitDalay={4} />
              </SwiperSlide>
            ))}
          {!mobile && (
            <StyledNav>
              <StyledPrev
                type="button"
                role="button"
                title="previous"
                aria-label="Go to previous"
                aria-disabled={beginning}
                disabled={beginning}
                className="Nav-Prev"
              >
                <Icon type="left-arrow-ellipse" />
              </StyledPrev>
              <StyledNext
                type="button"
                role="button"
                title="next"
                aria-label="Go to next"
                aria-disabled={end}
                disabled={end}
                className="Nav-Next"
              >
                <Icon type="right-arrow-ellipse" />
              </StyledNext>
            </StyledNav>
          )}
        </StyledSlider>
      </StyledSliderContainer>
    </StyledSliderWrapper>
  );
};

CardsSlider.propTypes = {
  slides: PropTypes.array.isRequired,
  parentPath: PropTypes.string.isRequired,
};

export default CardsSlider;
