import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const StyledSliderWrapper = styled.div`
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 60px 0;
  }
`;

export const StyledSliderContainer = styled.div`
  position: relative;
  margin-right: -20px;
  margin-left: -20px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-right: -60px;
    margin-left: -60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-right: -150px;
    margin-left: -150px;
  }

  @media (min-width: 1440px) {
    margin-right: calc((100vw - 1140px) / -2);
    margin-left: calc((100vw - 1140px) / -2);
  }

  .swiper-container {
    padding-bottom: 22px;

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      padding-bottom: 66px;
    }
  }

  .swiper-scrollbar {
    left: 50%;
    bottom: 0;
    width: 71.67%;
    height: 3px;
    background-color: ${(props) => props.theme.colors.grey};
    border-radius: 0;
    transform: translateX(-50%);

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      bottom: 15px;
      width: 53.108%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      width: 52.714%;
      height: 5px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      width: 680px;
    }

    .swiper-scrollbar-drag {
      background-color: ${(props) => props.theme.colors.lime};
      border-radius: 0;
    }
  }
`;

export const StyledSlider = styled(Swiper)`
  position: unset;
  padding-left: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-left: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding-left: 150px;
  }

  @media (min-width: 1440px) {
    padding-left: calc((100vw - 1140px) / 2);
  }
`;

export const StyledNav = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 87px;
  justify-content: space-between;
  display: none;
  
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    display: flex;
    left: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-left: 8.683%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    left: calc((100vw - 1140px) / 2);
    margin-left: 111px;
  }

  .Icon {
    path,
    circle {
      stroke: ${(props) => props.theme.colors.white};
    }
  }
`;

export const sliderNavStyles = css`
  background-color: transparent;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : 1)};
`;

export const StyledPrev = styled.button`
  ${sliderNavStyles};
`;

export const StyledNext = styled.button`
  ${sliderNavStyles};
`;
