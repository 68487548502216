import React, { useState, useEffect, useRef, useContext } from 'react';
import { gsap, Power1 } from 'gsap';
import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import Container from 'components/Container';
import ThemeLink from 'components/UI/ThemeLink';
import CardsSlider from 'components/CardsSlider';
import Breadcrumbs from 'components/UI/Breadcrumbs';
import {
  HelpWrapper,
  LinkCardsWrapper,
  LinkCardWrapper,
  LinkExcerpt,
} from './styles';

import linksAnimation from 'utils/animations/linksAnimation';
import breadcrumbsAnimation from 'utils/animations/breadcrumbsAnimation';

import AnimationContext from 'context/AnimationProvider';

const ParentResources = (props) => {
  const { isMobile } = useDeviceDetect();
  const { location, path } = props;
  const { resources, title } = props?.data?.contentfulParentResourcesPage;

  const { animationExit } = useContext(AnimationContext);

  const linksRef = useRef();
  const breadcrumbsRef = useRef();
  const tl = useRef(null);

  const [showRelated, setShowRelated] = useState(false);
  const [showExcerpt, setShowExcerpt] = useState({});

  useEffect(() => {
    const linkAnimTl = linksAnimation(linksRef);
    const breadcrumbsTl = breadcrumbsAnimation(breadcrumbsRef).timeScale(2);

    tl.current = gsap
      .timeline()
      .fromTo(
        breadcrumbsRef.current,
        { visibility: 'hidden' },
        { visibility: 'visible' },
        0
      )
      .add(breadcrumbsTl)
      .add(linkAnimTl, '-=1');

    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (animationExit) {
      setShowExcerpt({});
      tl.current.timeScale(3).reverse(0);
    }
  }, [animationExit]);

  const dropdownClick = () => {
    setShowRelated(!showRelated);
  };

  useEffect(() => {
    if (location.hash === "#dropdown") {
      const id = setTimeout(()=> {
        setShowRelated(true);
      }, 1700)

      return () => clearTimeout(id);
    }
  }, [location.hash])

  const itemHoverOn = (inx) => {
    setShowExcerpt((state) => ({
      ...state,
      [inx]: true,
    }));
  };

  const itemHoverOut = (inx) => {
    setShowExcerpt((state) => ({
      ...state,
      [inx]: false,
    }));
  };

  const exitPage = () => {
    setShowRelated(false);
    setShowExcerpt({});
    tl.current.timeScale(2.5).reverse(0);
  };

  const children = (body) => (
    <LinkExcerpt className="LinkExcerpt">{body}</LinkExcerpt>
  );

  const animatedChildren = (body, visible) => {
    const ref = useRef();

    useEffect(() => {
      if (visible) {
        // gsap.fromTo(
        //   ref.current,
        //   { opacity: 0 },
        //   { opacity: 0.8, duration: 0.5 }
        // );
      }
    }, [visible]);

    return (
      <LinkExcerpt className="LinkExcerpt" ref={ref}>
        {body}
      </LinkExcerpt>
    );
  };

  return (
    <HelpWrapper>
      <Container spacedTablet={true} spacedDesktop={false}>
        <Breadcrumbs
          props={props}
          setRef={breadcrumbsRef}
          exitDelay={2}
          onClick={exitPage}
          animated
        />
        <LinkCardsWrapper ref={linksRef}>
          {resources &&
            resources.map((r, inx) =>
              r.relatedResources ? (
                <LinkCardWrapper
                  key={r.id}
                  onMouseEnter={() => !isMobile && itemHoverOn(inx)}
                  onMouseLeave={() => !isMobile && itemHoverOut(inx)}
                  className={`${
                    showExcerpt[inx] && !showRelated ? 'is-hovered' : ''
                  }`}
                >
                  <ThemeLink
                    childEl={
                      r.excerpt &&
                      !showRelated &&
                      children(r.excerpt, showExcerpt[inx])
                    }
                    onClick={() => dropdownClick()}
                    bordered={inx !== resources.length - 1}
                    linkClassName="Help-Link"
                    ariaLabel={
                      !showRelated ? `Show ${r.title}` : `Hide ${r.title}`
                    }
                    ariaExpanded={showRelated}
                  >
                    {r.title}
                  </ThemeLink>
                  {showRelated && (
                    <CardsSlider
                      slides={r.relatedResources}
                      parentPath={path}
                      slideClick={exitPage}
                    />
                  )}
                </LinkCardWrapper>
              ) : (
                <LinkCardWrapper
                  key={r.id}
                  onMouseEnter={() => !isMobile && itemHoverOn(inx)}
                  onMouseLeave={() => !isMobile && itemHoverOut(inx)}
                  className={`${showExcerpt[inx] ? 'is-hovered' : ''}`}
                  onClick={exitPage}
                >
                  <ThemeLink
                    childEl={
                      r.excerpt && animatedChildren(r.excerpt, showExcerpt[inx])
                    }
                    to={`${path}/${r.handle}`}
                    key={r.id}
                    bordered={inx !== resources.length - 1}
                    linkClassName="Help-Link"
                    exitDelay={2}
                  >
                    {r.title}
                  </ThemeLink>
                </LinkCardWrapper>
              )
            )}
        </LinkCardsWrapper>
      </Container>
    </HelpWrapper>
  );
};

export default ParentResources;
